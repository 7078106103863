$white: #ffffff !default;
$black: #000 !default;
$grey: #6a6a6a !default;
$bg-grey: #f1f1f1 !default;
$red: #e8583c !default;
$yellow: #ffdb0a !default;
$blue: #10c9f2 !default;
$green: #37d647 !default;
$darkblue: #2775ca !default;

body {
    font-family: Inter-Regular;
}

a {
    color: $black;
    text-decoration: none;
}

.layout {
    width: 100%;
    position: relative;
    overflow: hidden;
}

.index-page-container {
    width: 100%;
    min-height: 100vh;
    padding: 60px 40px;
    background: radial-gradient(#8d1695, #1c0b83);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    z-index: -1;

    h1 {
        color: white;
    }

    @media (max-width: 768px) {
        padding: 15px;
    }
}

.page-container {
    width: 100%;
    min-height: 100vh;
    background: radial-gradient(#8d1695, #1c0b83);
    display: flex;
    align-items: center;
    flex-direction: column;
    z-index: -1;
    color: white;

    h1 {
        color: white;
    }

    h2 {
        color: white;
    }

    @media (max-width: 768px) {
        padding: 15px;
    }
}

.header {
    position: absolute;
    background-color: #160b53;
    top: 0px;
    padding: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    z-index: 2;

    .nav-tabs {
        border: none;
    }

    .logo {
        width: 80px;
        display: flex;
        justify-content: space-between;
        align-items: end;
    }

    .menu {
        margin: 0;
        padding: 0;
        position: relative;
        top: 15px;

        li {
            border-bottom-left-radius: 0px;
            border-bottom-right-radius: 0px;
            float: left;
        }
    }

    .balance {
        color: white;
    }

    .wallet {
        img {
            margin-right: 15px;
        }

        border: 1px solid $white;
        border-radius: 10px;
        padding: 15px;
        font-family: Inter-Regular;
        font-size: 14px;
        color: white;

        @media (max-width: 992px) {
            img {
                margin-right: 10px;
            }

            padding: 7px;
            font-size: 10px;
        }

        &:hover {
            cursor: pointer;
        }
    }
}


.bg-container {
    position: absolute;
    left: 0;
    right: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;

    #bg-img {
        height: 100%;
        opacity: 0.05;
    }
}

.action-button {
    background-color: #5730bfe3;
    min-width: 120px;
}

.action-button:hover {
    background-color: #5730bfe3;
}

.sub-link {
    font-size: 14px;
    font-family: Inter-Regular;
    border-radius: 10px;
    background-color: $white;
    padding: 15px 30px;
    background-color: #201463;

    @media (max-width: 992px) {
        padding: 15px 0px;
    }

    a {
        display: inline;
        border-right: 1px solid $black;
        padding: 5px 20px;
        margin: 10px 0;
        color: white;

        &:hover {
            font-family: Inter-Bold;
        }
    }
}

.tower-master {
    display: flex;
    flex-direction: column-reverse;

    .score-cell {
        background-color: #160b53 !important;
        border: 1px solid $grey;
        cursor: pointer;

        &:hover {
            background-color: $darkblue !important;
        }
    }

    .prize-cell {
        flex-direction: column;
        background-color: #24156d50 !important;
        border: 1px solid $grey;
        cursor: pointer;

        &:hover {
            background-color: $darkblue !important;
        }
    }

    .cell {
        width: 40px;
        height: 40px;
        background-color: $white;
        border: 1px solid $grey;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;

        &:hover {
            background-color: $grey;
        }

        @media (max-width: 545px) {
            width: 30px;
            height: 30px;
        }

        @media (max-width: 370px) {
            width: 25px;
            height: 25px;
        }

        @media (max-width: 334px) {
            width: 20px;
            height: 20px;
        }
    }

    .active-cell {
        background-color: $red;
        border: 1px solid $grey;

        &:hover {
            background-color: $grey;
        }
    }
}

.keno {
    display: flex;
    flex-direction: column;

    /* width */
    .history-container::-webkit-scrollbar {
        width: 10px;
    }

    /* Track */
    .history-container::-webkit-scrollbar-track {
        background: #9e9b9b00;
    }

    /* Handle */
    .history-container::-webkit-scrollbar-thumb {
        background: #a19d9d;
    }

    /* Handle on hover */
    .history-container::-webkit-scrollbar-thumb:hover {
        background: #555;
    }

    .history-container {
        min-height: 670px;
        max-height: 670px;
        overflow-y: scroll;
        min-width: 400px;
        max-width: 400px;
        border: solid 1px #888888;
        border-radius: 10px;
        margin-right: 30px;
        padding: 10px;
        color: gray;
        font-size: 14px;

        // -ms-overflow-style: none;
        // /* IE and Edge */
        // scrollbar-width: none;
        // /* Firefox */

        .total-bet-counter {
            border-bottom: solid 1px gray;
            margin-bottom: 5px;
        }

        .history-item {
            border: 1px solid gray;
            padding: 5px;
            border-radius: 5px;
            margin-top: 5px;
        }

        .picked-win-number {
            font-weight: 400;
            color: #fff;
            border-bottom: 1px solid #fff;
        }
    }

    .game-repeat {
        width: 180px;
        height: 40px;
        border-radius: 40px;
        border: solid 1px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 30px;
        color: #fff;
        font-weight: bold;
    }

    .current-round {
        width: 140px;
        height: 40px;
        border-radius: 10px;
        border: solid 1px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 10px;
        color: #fff;
        font-weight: bold;
    }

    .remain-round {
        width: 180px;
        height: 40px;
        border-radius: 40px;
        border: solid 1px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 10px;
        color: #fff;
        font-weight: bold;
    }

    .bet-amount {
        min-width: 150px;
        height: 40px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-left: 10px;
        color: #fff;
        font-weight: bold;
    }

    .board {
        display: flex;
        flex-flow: column;
        border: solid 1px #888888;
        border-radius: 10px;
        padding: 30px;
        min-height: 670px;
        max-height: 670px;


        .row {
            margin-left: 0px !important;
        }

        .reward-container {
            width: 100%;

            .horizontal-devider {
                width: 100%;
                border-bottom: 1px solid #fff;
                margin-top: 4px;
                margin-bottom: 4px;
            }

            .reward-match {
                width: 100%;
                display: flex;
                justify-content: space-between;

                div {
                    width: 100%;
                    display: flex;
                    justify-content: flex-end;
                    align-items: flex-start;
                }
            }
        }

        .board__card {
            margin-top: 5px;
        }

        .win-draw-number {
            opacity: 0.8;
            animation-name: shownum;
            animation-duration: 2s;
            animation-delay: 1s;
            animation-fill-mode: forwards;
            background-color: #10c9f2;
            border: #10c9f2 solid 3px;
        }

        .animation-draw-number {
            opacity: 0.8;
            animation-name: shownum;
            animation-duration: 2s;
            animation-delay: 1s;
            animation-fill-mode: forwards;
            background-color: #c5ee10e3;
            border: #10c9f2 solid 3px;
            z-index: 9999;
        }

        @keyframes shownum {
            from {
                opacity: 0.4;
            }

            to {
                opacity: 1;
            }
        }

        .count-container {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-bottom: 10px;
            min-height: 50px;
            max-height: 50px;
        }
    }

    .board.disabled .row,
    .submitCard:disabled {
        pointer-events: none;
    }

    .board.disabled>*:hover,
    .submitCard:disabled:hover {
        cursor: default;
    }

    .board .row {
        display: flex;
        width: 100%;
        justify-content: center;
    }

    .board {
        margin-bottom: 2em;
    }

    .full-screen-keno-submit {
        width: 100vw;
        height: 100%;
        position: absolute;
        top: 0px;
        left: 0px;
        // background-color: rgba(43, 41, 41, 0.671);
        z-index: 99999;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .full-screen {
        width: 100vw;
        height: 100%;
        position: absolute;
        top: 0px;
        left: 0px;
        background-color: rgba(43, 41, 41, 0.671);
        z-index: 9999;
        display: flex;
        justify-content: center;
        align-items: center;

        .count-down {
            width: 150px;
            height: 150px;
            background-color: gray;
            border-radius: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            h1 {
                color: #fff;
                font-size: 58px;
            }
        }
    }

    .board .row .col {
        width: 50px;
        max-width: 50px;
        line-height: 50px;
        text-align: center;
        border: solid 1px;
        border-color: rgba(255, 255, 255, .1) rgba(255, 255, 255, .2) rgba(255, 255, 255, .336);
        border-radius: 5px;
        margin: 3px;

        // background-color: $white;
        // border: 1px solid $grey;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;

        &:hover {
            background-color: $grey;
        }
    }

    .board .row .col:hover {
        cursor: pointer;
    }

    .board .row .col.picked span {
        display: none;
    }

    .board .row .col.picked:after {
        content: "🗙";
        color: $green;
        font-weight: 700;
    }

    .board .row .col.picked:hover {
        pointer-events: none;
        cursor: not-allowed;
    }

    .numbers,
    .options,
    .quik-pik {
        margin: 1em;
    }

    .quik-pik {
        display: flex;
        flex-direction: column;
    }

    .options {
        display: flex;
        flex-direction: column;
        text-align: left;
        margin-bottom: 1em;
    }

    .numbers {
        width: 300px;
    }

    .numbers__your,
    .numbers__matching {
        display: block;
        float: left;
        width: 300px;
    }

    .submit__card {
        margin-top: 1em;
        cursor: pointer;
    }

}

@font-face {
    font-family: "Inter-Black";
    src: url("./assets/font/Inter-Black.ttf");
}

@font-face {
    font-family: "Inter-Bold";
    src: url("./assets/font/Inter-Bold.ttf");
}

@font-face {
    font-family: "Inter-SemiBold";
    src: url("./assets/font/Inter-SemiBold.ttf");
}

@font-face {
    font-family: "Inter-Medium";
    src: url("./assets/font/Inter-Medium.ttf");
}

@font-face {
    font-family: "Inter-Regular";
    src: url("./assets/font/Inter-Regular.ttf");
}